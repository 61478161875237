import React, { CSSProperties } from "react";

interface INumericInput {
    name: string;
    defaultVal?: number;
    required?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dataTestId?: string;
    style?: CSSProperties;
    step?: string
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    min?: number;
}

const NumericInput: React.FC<INumericInput> = ({ name, defaultVal, required, onChange, dataTestId, style, step, onBlur, min }) => {
    const defaultStyles: CSSProperties = {
        height: '5vh',
        border: '1px solid #C4C4C4',
        borderRadius: '10px',
    };

    return (
        <input
            style={{...defaultStyles, ...style}}
            type="number"
            name={name}
            defaultValue={defaultVal}
            required={required}
            onChange={(event) => onChange ? onChange(event) : {}}
            onBlur={(event) => onBlur ? onBlur(event) : {}}
            data-testid={dataTestId || "numericInput"}
            step={step || "any"}
            min={min}
        />
    );
};

export default NumericInput;
