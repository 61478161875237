import React, { CSSProperties, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useServiceHook from "@hooks/useServiceHook";
import { SubscriptionService } from "@services/index";

import SubscriptionsListPanel from '@components/panels/SubscriptionsList/SubscriptionsList';
import { flexiOwnUsedPlanNames, flexiOwnPlanNames, SubscriptionPlan, ravCreditHirePlanNames, ravShareCoverPlanNames, ravShortTermPlanNames, preLovedPlanNames } from "@utils/constants/products";
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { ISubscriptionSummaryItem } from "@components/cards/SubscriptionSummary/SubscriptionSummary";
import CircularSpinner from "@components/spinners/Circular/Circular";
import { InfoBox, WarningBox } from '@components/cards/messageBox';
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";
import * as Sentry from "@sentry/react";
import breadcrumbObject from "SmartOpsHome/breadcrumbObject";


export default function CancellationSubscriptionsList() {
    const navigate = useNavigate();
    const { custId, country } = useParams();
    const { updateFormState } = useUserJourneyContext();

    Sentry.setTag("Cancellation", "Cancellation")
    Sentry.setTag("Start Time", new Date().toString())
    Sentry.setTag(`timed`, true)
    
    // loading and error SHALL NOT be true in the same time
    const [loading, error, errMessage, results] = useServiceHook(SubscriptionService.getSubscriptions, [custId, country]);

    const parseSubsPlan = (subs: ISubscriptionSummaryItem[]) => {  // assign standard plan name to each subscription item
        return subs.map((subs: ISubscriptionSummaryItem) => {
            if (flexiOwnPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.flexiOwn; }
            else if (flexiOwnUsedPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.flexiOwnUsed; }
            else if (ravCreditHirePlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.ravCreditHire; }
            else if (ravShareCoverPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.ravShareCover; }
            else if (ravShortTermPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.ravShortTerm; }
            else if (preLovedPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.preLoved; }
            else { subs.plan = SubscriptionPlan.flexi; }
            return subs;
        });
    };

    const subscriptions = loading === false ? parseSubsPlan(results) : [];

    const allInactive = subscriptions.every(subs => subs.status === 'Inactive');

    useEffect(() => {
        updateFormState(UserFormStates.driverDetails, { plan: 'Cancellation' });
        breadcrumbObject['Cancellation']['Cancellation Start Time'] = new Date();
    }, [results]);

    const selectSubscriptionLabelStyle: CSSProperties = {
        margin: '0',  // set to 0 to mitigate p tag's default margin value
        padding: '2vh 0 2vh 2.5vw',
        color: '#2C2A2D',
        fontWeight: '700',
        borderBottom: '1px solid #C4C4C4'
    };

    const isClickable = (subs: any): boolean => {
        return subs.status !== "Inactive"
    }


    return (<>
        <NavBar pageTitle="Cancellation" />
        <p style={selectSubscriptionLabelStyle}>
            Subscription history
        </p>
        {loading === true
            ? <LoadingSpinner />
            : subscriptions.length > 0
                ? <>
                    <SubscriptionsListPanel
                        keyName='cancellation-subscriptions'
                        items={subscriptions.map((subs: ISubscriptionSummaryItem) => {
                            return {
                                subsId: subs.subsId,
                                startDate: subs.startDate,
                                carDetails: subs.carDetails,
                                registration: subs.registration,
                                plan: subs.plan,
                                status: subs.status,
                                vehicleId: subs.vehicleId,
                                noticeGiven: subs.noticeGiven,
                                //If the subscription is not Inactive then the user is redirected from #/cancellation/{country}/{customerId} to 
                                //#/cancellation/{customerId}/{subsId}/{vehicleId} where the cancellation form is entered
                                onClickFn: isClickable(subs)
                                    ? () => {
                                        updateFormState(UserFormStates.cancellationDetails, { cancellationPlanType: subs.plan, noticeGiven: subs.noticeGiven })
                                        navigate(`/cancellation/${country}/${custId}/${subs.subsId}/${subs.vehicleId}/form`)
                                    }
                                    : undefined
                            }
                        })}
                    />
                    <div style={{ margin: '1.5vh 3.5vw' }}>
                        {allInactive && <WarningBox message='Please note that cancellations can only be processed for active subscriptions' />}
                    </div>
                </>
                : <NoSubscriptionsInfoNote />
        }
    </>);
};

const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);


const NoSubscriptionsInfoNote = () => (
    <div style={{ margin: '1.5vh 3.5vw' }}>
        <InfoBox message='Please note that the customer does not have any subscriptions on their record' />
    </div>
);