import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useServiceHook from '@hooks/useServiceHook';
import { VehicleService } from '@services/Vehicles/Vehicles';
import DropDownMenu from '@components/inputs/DropDownMenu';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import VehicleDetails from '@components/panels/VehicleDetails';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { dateToISOFormat } from '@utils/misc/functions';
import InfoBox from '@components/cards/messageBox/InfoBox';
import { Country } from '@utils/constants/localisation';
import NumericInput from '@components/inputs/NumericInput';
import { countrySpecificDistanceUnit } from '@utils/misc/functions';


interface IFleetioStatus {
    id: string,
    account_id: string,
    name: string,
    color: string,
};


export default function CancellationVehicleDetails() {
    const todaysDate = dateToISOFormat(new Date());

    const navigate = useNavigate();
    const { vehicleId, custId, subsId, country } = useParams();
    const { formState: { cancellationDetails, carInfo }, updateFormState } = useUserJourneyContext();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [vehicleDetailsLoading, , , vehicleDetails] = useServiceHook(
        VehicleService.getVehicleById,
        [vehicleId, country]
    );

    const [statusLoading, , , statuses] = useServiceHook(
        VehicleService.getVehicleStatuses,
        ['returning', country]
    );

    useEffect(() => {
        // Check if both vehicleDetails and statuses have been loaded
        if (statusLoading === false && vehicleDetailsLoading === false && statuses.length > 0) {
            // Save default values to the context when the component mounts
            updateFormState(UserFormStates.cancellationDetails, {
                endingKms: cancellationDetails.endingKms || vehicleDetails.current_meter_value || 0,
                fleetioStatusId: cancellationDetails.fleetioStatusId || statuses[0].id,
                fleetioStatusName: cancellationDetails.fleetioStatusName || statuses[0].name,
                noticeGiven: cancellationDetails.noticeGiven || todaysDate,
                cancellationDate: cancellationDetails.cancellationDate || todaysDate,
                contactId: custId
            });
            updateFormState(UserFormStates.carInfo, {
                make: vehicleDetails.make,
                model: vehicleDetails.model,
                year: vehicleDetails.year,
                colour: vehicleDetails.color,
                registrationPlate: vehicleDetails.license_plate,
                vin: vehicleDetails.vin,
                bodyType: vehicleDetails?.specs?.["body_type"] || "",
                vehicleId: vehicleId
            });
            updateFormState(UserFormStates.contractDetails, {
                subscriptionId: subsId,
            });
        }
    }, [statusLoading, vehicleDetailsLoading]);

    useEffect(  // when there is an ongoing request, disable the next step button
        () => setIsButtonDisabled(!(statusLoading === false && vehicleDetailsLoading === false)),
        [statusLoading, vehicleDetailsLoading]
    );

    const onSelectStatus = (event: ChangeEvent<HTMLSelectElement>) => {
        const status = event.currentTarget.value;
        const selectedLabel = event.target.options[event.target.selectedIndex].label;
        updateFormState(UserFormStates.cancellationDetails, { fleetioStatusId: status, fleetioStatusName: selectedLabel });
    };

    function convertDateFormat(inputDate: string) {
        const parts = inputDate.split('-');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return formattedDate;
    };

    const CancellationNotice = ({ country, cancellationDetails }: { country: Country, cancellationDetails: any }) => {
        if (country === Country.AU)
            return (<>
                <Label text='Notice given' styleCfg={{ bold: true }} />
                <p>
                    {cancellationDetails?.noticeGiven === null
                        ? 'N/A'
                        : convertDateFormat(cancellationDetails?.noticeGiven)}
                </p>
            </>);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5vh' }}>
                <Label text='Notice given' styleCfg={{ bold: true }} />
                <InfoBox message='Please refer to emails and notes to find the date the customer informed Splend they wished to cancel.' />
                <div>
                    <DatePicker
                        name='noticeGiven'
                        defaultVal={cancellationDetails.noticeGiven || todaysDate}
                        required={true}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            updateFormState(UserFormStates.cancellationDetails, { noticeGiven: e.target.value })} />
                </div>
            </div>
        );
    };

    return (<>
        <p>Please confirm that vehicle details are correct</p>
        {vehicleDetailsLoading === false || vehicleDetailsLoading === null
            ? <VehicleDetails
                make={vehicleDetails?.make || ''}
                model={vehicleDetails?.model || ''}
                year={vehicleDetails?.year || ''}
                color={vehicleDetails?.color || ''}
                licensePlate={vehicleDetails?.license_plate || ''}
                vin={vehicleDetails?.vin || ''}
                bodyType={carInfo?.bodyType || ''}
                startingMiles={vehicleDetails?.current_meter_value || ''}
            />
            : <LoadingSpinner />
        }
        {vehicleDetailsLoading === false || vehicleDetailsLoading === null
            ? <Section childCmp={<>
                <Label text= {`Ending ${countrySpecificDistanceUnit(String(country))}`}requiredFieldInd={true} />
                <NumericInput
                    style={textBoxStyle}
                    name='endingKms'
                    dataTestId="endingKms"
                    defaultVal={cancellationDetails.endingKms || vehicleDetails?.current_meter_value || 0}
                    min={vehicleDetails?.current_meter_value}
                    step = "any"
                    required={true}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        updateFormState(UserFormStates.cancellationDetails, { endingKms: e.target.value })}
                />
                <p>Please double check the odometer value in the vehicle as part of the cancellation process.</p>
            </>} />
            : <LoadingSpinner />
        }
        {statusLoading === false || statusLoading === null  // not null and not false means at least 1 req was made
            ? <Section childCmp={<>
                <Label text='Fleetio status for outgoing vehicle' requiredFieldInd={true} />
                <DropDownMenu
                    menuName='fleetioStatus'
                    required={true}
                    defaultVal={{value: cancellationDetails.fleetioStatusId, label: cancellationDetails.fleetioStatusName}}
                    choices={(statuses || []).map((item: IFleetioStatus) => ({
                            label: item.name,
                            value: item.id,
                            enabled: true
                        }))}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => onSelectStatus(e)}
                />
            </>} />
            : <LoadingSpinner />
        }

        <Section childCmp={<CancellationNotice country={country as Country} cancellationDetails={cancellationDetails} />} />

        <Section childCmp={<>
            <Label text={'Cancellation Date'} requiredFieldInd={true} />
            <DatePicker
                name='cancellationDate'
                data-testid="cancellationDate"
                defaultVal={cancellationDetails.cancellationDate || todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    updateFormState(UserFormStates.cancellationDetails, { cancellationDate: e.target.value })}
            />
        </>} />
    </>);
};


 // Wrapper component to reduce duplication of the same styling 
 // applied several times, which is meant to visually show separation. 
const Section = ({ childCmp }: { childCmp: JSX.Element }) => (
    <div style={{ margin: '2.5vh 0vw' }}>
        {childCmp}
    </div>
);


const LoadingSpinner = () => (
    <div style={{ height: '5vh' }}>
        <Elliptical />
    </div>
);
