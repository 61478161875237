import React, { useEffect } from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { countrySpecificCurrency, formatDateToDD_MMM_YYYY } from '@utils/misc/functions';
import InfoBox from '@components/cards/messageBox/InfoBox';
import { countrySpecificDistanceUnit } from '@utils/misc/functions';
import { formatCurrency } from '../../utils/misc/functions';


/**
 * View intended to be integrated into the Cancellation form.
 * 
 * All inputs are stored into the corresponding section of the Cancellation context.
 * 
 * All inputs default values are taken from the context.
 */
export default function CancellationReview() {

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, cancellationDetails, personalInfo, addressInfo, carInfo } = formState;

    const renderChargeSection = (label: any, value: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Label text={label} styleCfg={{ bold: true }} />
            </div>
            <p style={{ margin: 0 }} >{value || ''}</p>
        </div>
    );

    const CreditForUnusedDatesInfoBox = () => (
        <div style={{ margin: '1.5vh 3.5vw' }}>
            <InfoBox message='Credit for unused days will be refunded to customer if applicable in due course' />
        </div>
    );

    const AdditionalChargesInfoBox = ({ country }: { country: string }) => (
        <div style={{ margin: '1.5vh 3.5vw' }}>
            <InfoBox message={`Additional charges for excess ${countrySpecificDistanceUnit(country)} and infringements may be charged subsequently if applicable`} />
        </div>
    );

    const minimumTermChargesPerPlan = () => {
        if (cancellationDetails.cancellationPlanType == "Flexi Own") {
            return cancellationDetails.totalAcceptanceRemarketingFee;
        }
        return cancellationDetails.minimumPeriodCharge;
    };

    useEffect(
        () => (updateFormState(
            UserFormStates.cancellationDetails,
            { finalMinimumTermCharges: minimumTermChargesPerPlan() })
        ),
        []);

    return (
        <>
            <p>Please review ensuring all key details are correct ahead of processing cancellation</p>
            <div style={{ margin: '2.5vh 0vw' }}>
                {renderChargeSection(`First name`, personalInfo.firstName)}
                {renderChargeSection(`Last name`, personalInfo.lastName)}
                {renderChargeSection(`Email`, personalInfo.email)}
                {renderChargeSection(`Address`, addressInfo.street + ', ' + addressInfo.city + ', ' + addressInfo.postcode)}
            </div>
            <div style={{ margin: '2.5vh 0vw' }}>
                {renderChargeSection(`Make`, carInfo.make)}
                {renderChargeSection(`Model`, carInfo.model)}
                {renderChargeSection(`Year`, carInfo.year)}
                {renderChargeSection(`Colour`, carInfo.colour)}
                {renderChargeSection(`Registration plate`, carInfo.registrationPlate)}
                {renderChargeSection(`VIN`, carInfo.vin)}
                {renderChargeSection(`Body type`, carInfo.bodyType)}
            </div>
            <div style={{ margin: '2.5vh 0vw' }}>
                {renderChargeSection('Cancellation Type', cancellationDetails.cancellationType)}
                {renderChargeSection(`Cancellation Reason - Outcome`, cancellationDetails.cancellationCategory)}
                {renderChargeSection(`Cancellation Reason - Reason`, cancellationDetails.cancellationSubCategory)}
                <div>
                    <Label text={"Additional Comments"} styleCfg={{ bold: true }} />
                </div>
                <div style={{ overflowWrap: 'break-word' }}>
                    <p style={{ margin: 0 }}>{cancellationDetails.cancellationAdditionalInfo || ''}</p>
                </div>
                {renderChargeSection(`Customer returning date`, cancellationDetails.customerReturnDate || "N/A")}
                {renderChargeSection(`Add restriction to account`, cancellationDetails.cancellationAddRestrictionToAccount || "N/A")}

                {renderChargeSection(`Notice given`, cancellationDetails.noticeGiven && formatDateToDD_MMM_YYYY(cancellationDetails.noticeGiven) || "N/A")}
                {renderChargeSection(`Cancellation date`, formatDateToDD_MMM_YYYY(cancellationDetails.cancellationDate))}
            </div>
            <div style={{ margin: '2.5vh 0vw' }}>
                {renderChargeSection(`Total ancillary fee (${countrySpecificCurrency(driverDetails.country)})`, formatCurrency(cancellationDetails.totalAncillaryCharges) || formatCurrency("0"))}
                {renderChargeSection(`Payment holiday charge (${countrySpecificCurrency(driverDetails.country)})`, formatCurrency(cancellationDetails.phCharges) || formatCurrency("0"))}
                {renderChargeSection(`Notice period charge (${countrySpecificCurrency(driverDetails.country)})`, formatCurrency((cancellationDetails.noticePeriodCharge )) || formatCurrency("0"))}
                {renderChargeSection(`Minimum period charge (${countrySpecificCurrency(driverDetails.country)})`, formatCurrency(minimumTermChargesPerPlan()) || formatCurrency("0"))}
                {renderChargeSection(`Total due today (${countrySpecificCurrency(driverDetails.country)})`, formatCurrency(cancellationDetails.totalCancellationCharges) || formatCurrency("0"))}
            </div>

            <CreditForUnusedDatesInfoBox />

            <AdditionalChargesInfoBox country={driverDetails.country} />
        </>
    );
};
