import React, { useEffect } from 'react';

import Label from '@components/inputs/text/Label';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { Country } from '@utils/constants/localisation';
import { textBoxStyle } from '@utils/styles/textboxStyle';


export default function CancellationAddressDetails() {

    const streetNumberAndName = 'Street number and name';
    const city = 'City';
    const state = 'State';
    const postcode = 'Postcode';
    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };

    const { formState: { addressInfo, driverDetails }, updateFormState } = useUserJourneyContext();

    const provinceByCountry: { [key in Country]: string[] } = {
        'GB': ['LDN'],
        'AU': ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']
    };

    const handleAddressInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case streetNumberAndName:
                updateFormState(UserFormStates.addressInfo, { street: e.currentTarget.value });
                break;
            case city:
                updateFormState(UserFormStates.addressInfo, { city: e.currentTarget.value });
                break;
            case state:
                updateFormState(UserFormStates.addressInfo, { state: e.currentTarget.value });
                break;
            case postcode:
                updateFormState(UserFormStates.addressInfo, { postcode: e.currentTarget.value });
                break;
        }
    };

    // The useEffect was added because addressInfo.state is left blank for UK customers
    // after customerDetails call/hook is done. The changes here are more a workaround,
    // long term solution should be implemented within customer details hook.
    useEffect(
        () => { driverDetails.country === Country.GB && updateFormState(UserFormStates.addressInfo, { state: 'LDN' }) },
        []
    );

    return (<>
        <p style={{ margin: '2.5vh 0' }}>Please review the customer's <strong>Residential address</strong> ensuring
            that each field is populated with the correct data.</p>
        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Unit/Street number and Street name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={streetNumberAndName} style={textBoxStyle} required defaultValue={addressInfo.street} onChange={handleAddressInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={driverDetails.country === 'AU' ? 'City' : 'Town/City'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={city} style={textBoxStyle} required defaultValue={addressInfo.city} onChange={handleAddressInfoChange} />
        </div>

        {driverDetails.country === Country.AU &&
            <div style={{ margin: '2.5vh 0' }}>
                <Label
                    text={'State'}
                    styleCfg={{ largeFont: false, bold: false }}
                    requiredFieldInd={true}
                />
                <DropDownMenu
                    menuName={state}
                    defaultVal={addressInfo.state
                        ? { label: addressInfo.state, value: addressInfo.state, enabled: true }
                        : dropDownPleaseSelect}
                    required={true}
                    choices={[dropDownPleaseSelect].concat(provinceByCountry[Country[driverDetails.country]].map((item: any) => ({ label: item, value: item, enabled: true })))}
                    onSelect={handleAddressInfoChange}
                />
            </div>
        }

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Postcode'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={postcode} style={textBoxStyle} required defaultValue={addressInfo.postcode} onChange={handleAddressInfoChange} />
        </div>
    </>);
};
